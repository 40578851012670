import './index.css';

import { StrictMode, Suspense, lazy } from 'react';

import LoaderLite from "components/LoaderLite";
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';

const App = lazy(() => import('./InternetApp'));

ReactDOM.render(
    <StrictMode>
        <Suspense fallback={LoaderLite} >
            <App />
        </Suspense>
    </StrictMode>,
    document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

export const containerStyle = {
    display: "flex",
    width: "100vw",
    height: "100vh",
    alignItems: "center",
    justifyContent: "center"
};

const circleStyle = {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    backgroundColor: "#00afd0",
    borderRadius: "50%",
    width: "100%",
    height: "100%"
}

const svgStyle = {
    width: 56, 
    height: 56,
    animationDuration: "1s", 
    animationIterationCount: "infinite",
    animationDirection: "forward", 
    transformOrigin: "center center",
    animationName: "d02fa", 
    animationTimingFunction: "linear",
    flex: "0 0 auto"
}


const LoaderLite = (
    <div style={containerStyle}>
        <div style={{ width: 72, height: 72 }}>
            <div style={circleStyle}>
                <svg style={svgStyle} viewBox="0 0 24 24">
                    <g>
                        <path fill="#fff" transform="translate(4, 4)" d="M14.4 8c0 3.529-2.871 6.4-6.4 6.4-3.53 0-6.4-2.871-6.4-6.4 0-3.53 2.87-6.4 6.4-6.4V0a8 8 0 108 8h-1.6z"></path>
                    </g>
                </svg>
            </div>
        </div>
    </div>
)


export default LoaderLite;